// ! Only add scss variables here, DO NOT include any css rendered styles!!!!!!!!!!!!!

.global-footer-container{
    $border-width: 4px;

    position: relative;
    z-index: 10;
    margin-top: 50px;
    // width: 100%;
    background: #E1DFD4;
    font-family: "vista-slab", serif;

    // font-family: 'Norwester';

    .global-footer{
        display: flex;
        justify-content: space-between;
        width: calc(100% - 2 * $border-width);
        height: calc(100% - 2 * $border-width);
        border: $border-width solid #C4B197;

        

        .navigation{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 100%;
            margin-bottom: 20px;
        }

        .sections{
            // $skew-angle: 20deg;

            display: grid;
            margin: 30px 20%;
            grid-template-columns: repeat(3, 1fr);
            // align-items: center;
            justify-content: center;
            font-size: 20px;
            // text-align: center;

            .section-links{
                font-size: 15px;

                .section-header{
                    font-size: 20px;
                    color: black;
                    font-weight: bold;
                    margin-bottom: 5px;
                }

                :not(.address) + .address{
                    margin-top: 20px;
                }

                a{
                    color: black;
                    text-decoration: none;
                }
            }
        }

        .legal-disclaimer{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80%;
            border-top: 2px solid black;
            margin: auto;
            padding-top: 20px;

        }

        .logo{
            display: inline-flex;
            gap: 10px;
            width: 20%;
            height: 100%;
            margin-right: 20px;
            cursor: pointer;

            .icon{
                $icon-padding: 20px;

                padding: 20px;
                width: 75px;
                width: calc(100% - 2 * $icon-padding);
                height: calc(100% - 2 * $icon-padding);
            }

            .title{
                width: 150px;
            }

            .icon, .title{
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }

            .icon svg{
                height: 100%;
            }
        }
    }
}

@media only screen and (max-width: 1050px) {
    .global-footer-container{
        .global-footer{
            flex-direction: column;
        }
        .global-footer .navigation .sections{
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 10px 10%;
            padding-left: 10px;
        }

        .global-footer .logo{
            width: 100%;
        }
    }

}
